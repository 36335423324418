import React from 'react';

import MainLayout from '../components/layouts/MainLayout';
import SEO from '../components/system/SEO';
import { usePageList } from '../hooks/usePageList';

const NotFoundPage = ({ location }) => {
  const sitePages = usePageList();
  return (
    <MainLayout location={location} pages={sitePages}>
      <SEO />
      Error
    </MainLayout>
  );
};

export default NotFoundPage;
